import React from "react";
import { Container } from "react-bootstrap";
import { Element } from "react-scroll";
import "./section-6.scss";
import { useTranslation } from "react-i18next";

const Section6 = () => {
  const { t } = useTranslation();

  return (
    <Element name="faq" className="section-6" style={{ overflow: "hidden" }}>
      <Container>
        <div className="title">{t("Часто задаваемые вопросы (FAQ)")}</div>
       <div id="accordion1">
         <div className="row">
           <div className="col-xl-6">
             <div className="card">
               <div className="card-body">
                 <a
                     className="btn"
                     data-bs-toggle="collapse"
                     href="#collapseOne"
                 >
                  <span>
                    {t("Какие результаты я могу ожидать от ваших услуг?")}
                  </span>
                   <img src="/assets/images/arrow-down.svg" alt="" />
                 </a>

                 <div
                     id="collapseOne"
                     className="collapse show"
                     data-bs-parent="#accordion1"
                 >
                   <div className="text">
                     {t(
                         "Результаты от услуг зависят от ваших целей и выбранного тарифного плана, но обычно это повышение узнаваемости бренда, увеличение трафика на сайт и улучшение вовлеченности аудитории."
                     )}
                   </div>
                 </div>
               </div>
             </div>

             <div className="card">
               <div className="card-body">
                 <a
                     className="collapsed btn"
                     data-bs-toggle="collapse"
                     href="#collapseTwo"
                 >
                  <span>
                    {t("Каковы стоимость и структура вашего тарифного плана?")}
                  </span>
                   <img src="/assets/images/arrow-down.svg" alt="" />
                 </a>
                 <div
                     id="collapseTwo"
                     className="collapse"
                     data-bs-parent="#accordion1"
                 >
                   <div className="text">
                     {t(
                         "Цена и структура тарифного плана обычно представлены в виде пакетов услуг с разным уровнем обслуживания и ценой."
                     )}
                   </div>
                 </div>
               </div>
             </div>

             <div className="card">
               <div className="card-body">
                 <a
                     className="collapsed btn"
                     data-bs-toggle="collapse"
                     href="#collapseThree"
                 >
                   <span> {t("Предоставляете ли вы отчетность о проделанной работе и ее эффективности?")}</span>
                   <img src="/assets/images/arrow-down.svg" alt="" />
                 </a>
                 <div
                     id="collapseThree"
                     className="collapse"
                     data-bs-parent="#accordion1"
                 >
                   <div className="text">
                     {t("Да, мы предоставляем регулярные отчеты о проделанной работе, анализ эффективности стратегий и достигнутых результатов.")}

                   </div>
                 </div>
               </div>
             </div>
           </div>

           <div className="col-xl-6">
             <div className="card">
               <div className="card-body">
                 <a
                     className="btn"
                     data-bs-toggle="collapse"
                     href="#collapseFour"
                 >
                  <span>
                    {t(
                        "Сколько времени займет достижение желаемых результатов? "
                    )}
                  </span>
                   <img src="/assets/images/arrow-down.svg" alt="" />
                 </a>
                 <div
                     id="collapseFour"
                     className="collapse"
                     data-bs-parent="#accordion1"
                 >
                   <div className="text">
                     {t(
                         "Время получения результатов может варьироваться от нескольких недель до нескольких месяцев в зависимости от сложности задачи и конкуренции в вашей отрасли."
                     )}
                   </div>
                 </div>
               </div>
             </div>

             <div className="card">
               <div className="card-body">
                 <a
                     className="collapsed btn"
                     data-bs-toggle="collapse"
                     href="#collapseSix"
                 >
                  <span>
                    {t(
                        "Есть ли у вас какие-либо знания или опыт в моей области?"
                    )}
                  </span>
                   <img src="/assets/images/arrow-down.svg" alt="" />
                 </a>
                 <div
                     id="collapseSix"
                     className="collapse"
                     data-bs-parent="#accordion1"
                 >
                   <div className="text">
                     {t(
                         "Мы имеем опыт работы в различных отраслях и готовы адаптировать наши стратегии к конкретным потребностям вашего бизнеса."
                     )}
                   </div>
                 </div>
               </div>
             </div>
             <div className="card">
               <div className="card-body">
                 <a
                     className="collapsed btn"
                     data-bs-toggle="collapse"
                     href="#collapseFive"
                 >
                  <span>
                    {t(
                        "Предоставляете ли вы гарантии качества выполненной работы?"
                    )}
                  </span>
                   <img src="/assets/images/arrow-down.svg" alt="" />
                 </a>
                 <div
                     id="collapseFive"
                     className="collapse"
                     data-bs-parent="#accordion1"
                 >
                   <div className="text">
                     {t(
                         "Да, мы гарантируем качество нашей работы и готовы вносить коррективы по мере необходимости для достижения наилучших результатов."
                     )}
                   </div>
                 </div>
               </div>
             </div>


           </div>
         </div>
       </div>

      </Container>
    </Element>
  );
};

export default Section6;
