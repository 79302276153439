import React from "react";
import {Container} from "react-bootstrap";
import {Element} from "react-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./section-5.scss";
import {useTranslation} from "react-i18next";
import PartnerBlack6 from "../../assets/img/portfolio/desktop/6-b.png";
import PartnerBlack11 from "../../assets/img/portfolio/desktop/11-b.png";

const Section5 = () => {
    const {t} = useTranslation();

    const settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplayHoverPause:true,
        // autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    };
    return (
        <Element
            name="reviews"
            className="section-5"
            style={{overflow: "hidden"}}
        >
            <Container>
                <div className="title text-uppercase">
                    {t("Что говорят о нас наши клиенты ?")}
                </div>

                <Slider {...settings}>
                    <div className="box">
                        <div className="box-header">
                            <iframe
                                src="https://www.youtube.com/embed/3rdmIorpQQo?si=wW70-fXq0X9Rlf0-"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>
                        </div>
                        <div className="box-footer">
                            <a href="https://www.instagram.com/doctorislomov/">
                                <img style={{width: "120px"}}  src={PartnerBlack11} alt="" />
                            </a>
                            {/*<img src="/assets/carousel/islomov.png" alt=""/>*/}
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-header">
                            <iframe
                                src="https://www.youtube.com/embed/_KGuALO4Apc"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>
                        </div>
                        <div className="box-footer">
                            <a href="https://cleaninggunaydin.uz/">
                                <img style={{width: "120px"}} src={PartnerBlack6} alt=""/>
                            </a>
                            {/*<img src="/assets/carousel/clean.png" alt=""/>*/}
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-header">
                            <iframe
                                    src="https://www.youtube.com/embed/G1TswWcsRi0?si=nPFL5u45NXFsNH0n"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen/>
                        </div>
                        <div className="box-footer">

                            <a href="https://www.instagram.com/irodakhon_shokhakimova/"
                            className="d-flex align-items-center text-decoration-none text-dark"
                            >
                                <img style={{width:"40px"}} src="/assets/images/instagram-line.svg" alt="" />
                                <b>Irodakhon_shokhakimova</b>
                            </a>
                        </div>
                    </div>

                    <div className="box">
                        <div className="box-header">
                            <iframe
                                src="https://www.youtube.com/embed/3rdmIorpQQo?si=wW70-fXq0X9Rlf0-"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>
                        </div>
                        <div className="box-footer">
                            <a href="https://www.instagram.com/doctorislomov/">
                                <img style={{width: "120px"}}  src={PartnerBlack11} alt="" />
                            </a>
                            {/*<img src="/assets/carousel/islomov.png" alt=""/>*/}
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-header">
                            <iframe
                                src="https://www.youtube.com/embed/_KGuALO4Apc"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>
                        </div>
                        <div className="box-footer">
                            <a href="https://cleaninggunaydin.uz/">
                                <img style={{width: "120px"}} src={PartnerBlack6} alt=""/>
                            </a>
                            {/*<img src="/assets/carousel/clean.png" alt=""/>*/}
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-header">
                            <iframe
                                src="https://www.youtube.com/embed/G1TswWcsRi0?si=nPFL5u45NXFsNH0n"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>
                        </div>
                        <div className="box-footer">

                            <a href="https://www.instagram.com/irodakhon_shokhakimova/"
                               className="d-flex align-items-center text-decoration-none text-dark"
                            >
                                <img style={{width:"40px"}} src="/assets/images/instagram-line.svg" alt="" />
                                <b>Irodakhon_shokhakimova</b>
                            </a>
                        </div>
                    </div>

                </Slider>
            </Container>
        </Element>
    );
};

export default Section5;
