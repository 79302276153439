import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import { Element } from "react-scroll";
import VisibilitySensor from "react-visibility-sensor";
import Number1 from "../../assets/img/portfolio/number-1.png";
import "./fact-about-us.scss";

const FactAboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Element
        name="about"
        className="fact-about-us"
        style={{ overflow: "hidden" }}
      >
        <Container>
          <div className="parent-box">
            <Row>
              <Col md={12}>
                <Fade top delay={500} duration={1000}>
                  <div className="top">
                    <div className="title">{t("Факты о нас ")}</div>
                    <div className="commit">
                      {t(
                        "Ознакомьтесь с нашим портфолио, где каждый проект — это история успеха. Мы гордимся достижениями наших клиентов и готовы воплотить в жизнь вашу уникальную идею."
                      )}
                    </div>
                  </div>
                </Fade>
              </Col>
            </Row>
            <div className="actions">
              <Row className="">
                <Col md={3} xs={12}>
                  <div className="action">
                    <div className="top">
                      <span className="numbers-box">
                        <CountUp start={0} end={5} duration={2} redraw={true} suffix="+">
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <>
                                <img src={Number1} alt="" />
                                <span ref={countUpRef} className="number" />
                              </>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </span>
                    </div>
                    <div className="action-title">{t("Лет опыта")}</div>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div className="action">
                    <div className="top">
                      <span className="numbers-box">
                        <CountUp start={0} end={80} duration={2} redraw={true} suffix="+">
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <>
                                <img src={Number1} alt="" />
                                <span ref={countUpRef} className="number" />
                              </>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </span>
                    </div>
                    <div className="action-title">
                      {t("Проектов в СММ / мес")}
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div className="action">
                    <div className="top">
                      <span className="numbers-box">
                        <CountUp start={0} end={20} duration={2} redraw={true} suffix="+">
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <>
                                <img src={Number1} alt="" />
                                <span ref={countUpRef} className="number" />
                              </>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </span>
                    </div>
                    <div className="action-title">
                      {t("Специалистов сферы")}
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div className="action">
                    <div className="top">
                      <span className="numbers-box">
                        <CountUp start={0} end={999} duration={2} redraw={true} suffix="+">
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <>
                                <img src={Number1} alt="" />
                                <span ref={countUpRef} className="number" />
                              </>
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      </span>
                    </div>
                    <div className="action-title">{t("Тонн креатива")}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </Element>
    </>
  );
};

export default FactAboutUs;
