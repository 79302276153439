import React, {useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {Element} from "react-scroll";
import emailjs from "@emailjs/browser";
import "./form-component.scss";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const FormComponent = () => {
    const [phoneNumber, setPhoneNumber] = useState('+998');
    const [isValid, setIsValid] = useState(true);

    const handlePhoneNumberChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.startsWith('+') && !/[^0-9]/.test(inputValue.slice(1))) {
            setPhoneNumber(inputValue);
            setIsValid(true); // Reset validation on valid input
        } else {
            setIsValid(false);
        }
    };

    const validatePhoneNumber = () => {
        const phonePattern = /^\+998\d{9,}$/;
        return phonePattern.test(phoneNumber);
    };

    const {t} = useTranslation();
    const form = useRef();

    function sendInfo(e) {
        e.preventDefault();
        const isPhoneNumberValid = validatePhoneNumber();
        setIsValid(isPhoneNumberValid);
        if (isPhoneNumberValid){

            emailjs.sendForm('service_0deewem', 'template_h1zrv42',
                form.current, 'xD1743EGQqHgtYu2M').then(
                (result) => {
                    console.log(result);
                    e.target.reset();
                    toast.success("Данные успешно отправлены");
                },
                (error) => {
                    console.log(error);
                    toast.success("Произошла ошибка при отправке данных");
                },
            );

        }
    }

    return (
        <Element
            name="form"
            className="form-component"
            style={{overflow: "hidden"}}
        >
            <Container>
                <div className="parent-element">
                    <div className="parent-element-left">
                        <div className="parent-element-title text-uppercase">
                            {t("Надёжное решение для вашего бизнеса")}
                        </div>
                        <a href="tel:+998333303304" className="parent-element-button text-capitalize">
                            {t("позвонить")}
                        </a>
                    </div>
                    <div className="parent-element-right">
                        <img src="/assets/images/businesswoman.png" alt="sasa"/>
                    </div>
                </div>
                <div className="box">
                    <div className="box-left ">
                        <div className="box-title text-uppercase">
                            {t("Начните новый этап развития с нами")}
                        </div>
                        <div className="box-text">
                            {t("Оставьте заявку для бесплатной консультации")}
                        </div>
                    </div>
                    <div className="box-right ">
                        <form ref={form} onSubmit={sendInfo}>
                            <div className="d-flex mobile-form">
                                <input
                                    required={true}
                                    placeholder={t("Имя")}
                                    name="name"
                                    className="form-control me-4"
                                    type="text"/>

                                <input
                                    className="form-control"
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    placeholder="+998"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    required={true}
                                    style={{ border: isValid ? '' : '2px solid red' }}
                                />
                            </div>
                           <div className="d-flex justify-content-between">
                               <small className="w-75"/>
                               {!isValid &&
                               <small className="text-danger ms-4">
                                   {t("Пожалуйста, введите действительный номер телефона, начинающийся с +998 и содержащий не менее 9 цифр")}</small>}
                           </div>
                            <div className="d-flex mt-20 mobile-form">
                                <input
                                    required={true}
                                    placeholder={t("Название Компании")}
                                    name="company"
                                    className="form-control me-4"
                                    type="text"/>
                                <button type={'submit'}>
                                    {t("Отправить")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </Element>
    );
};

export default FormComponent;
