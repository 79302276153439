import { useEffect, useState } from "react";
import "./App.css";
import Loading from "./components/loading/Loading";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import Header from "./sections/header/Header";
import Section1 from "./sections/section-1/Section1";
import Section3 from "./sections/section-3/Section3";
import Section4 from "./sections/section-4/Section4";
import Section5 from "./sections/section-5/Section5";
import Section6 from "./sections/section-6/Section6";
import FormComponent from "./sections/form/FormComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./sections/footer/Footer";
import Portfolio from "./sections/portfolio/Portfolio";
import Services from "./sections/services/Services";
import FactAboutUs from "./sections/fact-about-us/FactAboutUs";
import Top from "./components/top/Top";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Top />
      <Header />
      <FactAboutUs />
      <Portfolio />
      <Services />
      <Section3 />
      <Section4 />
      <FormComponent />
      <Section5 />
      <Section6 />
      <Footer />
      <ScrollToTop />

      <ToastContainer />

      {/* <Fade top delay={500} duration={1000}>
                  
                  </Fade> */}
    </div>
  );
}

export default App;
