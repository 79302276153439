import React from "react";
import { Container } from "react-bootstrap";
import { Element } from "react-scroll";
import "./section-4.scss";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

const Section4 = () => {
  const { t } = useTranslation();

  return (
    <Element name="about" className="section-4" style={{ overflow: "hidden" }}>
      <Container>
        <Fade top delay={500} duration={1000}>
          <div className="title text-uppercase">
            {t("Ваш проект всегда в приоритете !")}
          </div>
        </Fade>
        <div className="row">
          <div className="col-xl-4">
            {/*<Fade left delay={500} duration={1000}>*/}
              <div className="box">
                <img src="/assets/images/inovation.png" alt="" />
                <div className="box-title">{t("Индивидуальный подход")}</div>
                <div className="box-text">
                  {t(
                    "Уникальные проекты, индивидуальные решения. Ваша история - наш приоритет."
                  )}
                </div>
              </div>
            {/*</Fade>*/}
          </div>
          <div className="col-xl-4">
            {/*<Fade bottom delay={500} duration={1000}>*/}
              <div className="box">
                <img src="/assets/images/personal-aproach.png" alt="" />

                <div className="box-title">{t("Качество и точность")}</div>
                <div className="box-text">
                  {t(
                    "Каждая деталь важна. Наши решения — это встреча высочайшего стандарта и ваших ожиданий."
                  )}
                </div>
              </div>
            {/*</Fade>*/}
          </div>
          <div className="col-xl-4">
            {/*<Fade right delay={500} duration={1000}>*/}
              <div className="box">
                <img src="/assets/images/expertise.png" alt="" />
                <div className="box-title pt-1">
                  {t("Профессионализм и соблюдение сроков")}
                </div>
                <div className="box-text">
                  {t(
                    "Профессионализм в каждом шаге. Соблюдаем сроки, чтобы ваш успех всегда был вовремя."
                  )}
                </div>
              </div>
            {/*</Fade>*/}
          </div>
        </div>
      </Container>
    </Element>
  );
};

export default Section4;
