import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Element } from "react-scroll";
import "./services.scss";
import ServiceImage1 from "../../assets/img/services/service-image-1.png";
import ServiceImage2 from "../../assets/img/services/service-image-2.png";
import ServiceImage3 from "../../assets/img/services/service-image-3.png";
import ServiceImage4 from "../../assets/img/services/service-image-4.png";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <>
      <Element
        name="services"
        className="services"
        style={{ overflow: "hidden" }}
      >
        <Container>
          <div className="parent-box">
            <Row>
              <Col md={12}>
                <Fade top delay={500} duration={1000}>
                  <div className="top">
                    <div className="title">{t("Услуги")}</div>
                    <div className="commit">
                      {t(
                        "Наши услуги нацелены на максимальное удовлетворение потребностей клиентов. От разработки креативных концепций до стратегического маркетинга — выбирайте из наших тарифов и получайте результаты, которые превзойдут ваши ожидания."
                      )}
                    </div>
                  </div>
                </Fade>
              </Col>
            </Row>

            <div className="services-cards">
              <div className="card">
                <div className="card-body">
                  <img src={ServiceImage1} alt="" />
                  <div className="card-title">
                    {t("Социальный медиа маркетинг")}
                  </div>
                  <div className="card-desc">
                    {t(
                      "Продвижение и управление вашим брендом в социальных сетях, создание контента, анализ эффективности кампаний."
                    )}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <img src={ServiceImage2} alt="" />
                  <div className="card-title">{t("Платная реклама")}</div>
                  <div className="card-desc">
                    {t(
                      "Размещение рекламных материалов на платформах, таких как Facebook Ads, Google Ads, Яндекс.Директ, социальные сети, для привлечения целевой аудитории."
                    )}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <img src={ServiceImage3} alt="" />
                  <div className="card-title">{t("Брендинг")}</div>
                  <div className="card-desc">
                    {t(
                      "Разработка уникального стиля бренда, логотипа, фирменного стиля, а также стратегии его продвижения на рынке."
                    )}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <img src={ServiceImage4} alt="" />
                  <div className="card-title">{t("Веб-сайт")}</div>
                  <div className="card-desc">
                    {t(
                      "Создание и разработка сайтов с учетом индивидуальных потребностей клиента, оптимизация для поисковых систем, поддержка и обновление контента."
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Element>
    </>
  );
};

export default Services;
