import React from "react";
import {Container} from "react-bootstrap";
import {Element} from "react-scroll";
import "./section-3.scss";
import {useTranslation} from "react-i18next";

const Section3 = () => {
    const { t } = useTranslation();
    return (
        <Element
            name="advantages"
            className="section-3"
            style={{overflow: "hidden"}}
        >
            <Container>
                <div className="row">
                    <div className="col-xl-6 text-center">
                        <div className="phone-img">
                            <img src="/assets/images/phone.png" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="title text-uppercase">
                            {t("ПреимуществА работы с нами")}
                        </div>

                        <div id="accordion">
                            <div
                                className="step-one"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                            >
                                <div className="d-flex align-items-center">
                                    <div className="circle">1</div>
                                    <div className="step-title">
                                        {t("Инновации в каждом проекте")}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne"
                                    className="collapse show"
                                    data-bs-parent="#accordion"
                                >
                                    <div className="step-text">
                                        {t(
                                            "Мы предоставляем передовые технологии и инновационные решения, чтобы ваш проект всегда выделялся."
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="step-one"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                            >
                                <div className="d-flex align-items-center">
                                    <div className="circle">2</div>
                                    <div className="step-title">{t("Личный подход")}</div>
                                </div>

                                <div
                                    id="collapseTwo"
                                    className="collapse"
                                    data-bs-parent="#accordion"
                                >
                                    <div className="step-text">
                                        {t(
                                            "У нас нет шаблонов. Сотрудничая с нами, вы получаете индивидуальное внимание и решения, адаптированные к вашим потребностям."
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="step-one"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                            >
                                <div className="d-flex align-items-center">
                                    <div className="circle">3</div>
                                    <div className="step-title">
                                        {t("Экспертность без компромиссов")}
                                    </div>
                                </div>
                                <div
                                    id="collapseThree"
                                    className="collapse"
                                    data-bs-parent="#accordion"
                                >
                                    <div className="step-text">
                                        {t(
                                            "Наша команда опытных профессионалов гарантирует высочайший уровень профессионализма на каждом этапе вашего проекта."
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Container>
        </Element>
);
};

export default Section3;
