import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Element } from "react-scroll";
import "./header.scss";
import Navbar from "../../components/navbar/Navbar";
import Fade from "react-reveal/Fade";
import BgVideo from "../../assets/videos/bg2.mp4";
import { useTranslation } from "react-i18next";
import { Link, animateScroll as scroll } from "react-scroll";

const Header = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <Element name="home" className="header" style={{ overflow: "hidden" }}>
        <>
          <video
            src={BgVideo}
            playsInline
            autoPlay
            loop
            muted
            className="back-video"
          />
          <div className="overlay"></div>
        </>

        <Container>
          <div className="parent-box">
            <Row>
              <Col md={7}>
                <div className="left-box">
                  <Fade left delay={500} duration={1000}>
                    <div className="title">
                      {t(
                        "Приведите свой бизнес к новым высотам с нашей компанией!"
                      )}
                    </div>
                  </Fade>
                  <Fade bottom delay={500} duration={1000}>
                    <div className="commit">
                      {t("Надежные решения для вашего успеха.")}
                    </div>
                  </Fade>
                  <Link
                    className="btn"
                    to={"form"}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    // offset={offset}
                    duration={100}
                    delay={100}
                    isDynamic={true}
                    ignoreCancelEvents={false}
                    spyThrottle={500}
                  >
                    {t("Бесплатная Консультация")}
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Element>
    </>
  );
};

export default Header;
