import React from "react";
import "./top.scss";
import { useTranslation } from "react-i18next";

const Top = () => {
  const { t } = useTranslation();

  return (
    <div className="site-top">
      <div className="text">
        {t("Команда экспертов по маркетингу и созданию сайтов. +998 (33)-330-33-04")}
      </div>
      <div className="text-2">+998 (33)-330-33-04</div>
    </div>
  );
};

export default Top;
