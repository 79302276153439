import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { Element } from "react-scroll";

import Partner1M from "../../assets/img/portfolio/mobile/1.png";
import Partner2M from "../../assets/img/portfolio/mobile/2.png";
import Partner3M from "../../assets/img/portfolio/mobile/3.png";
import Partner4M from "../../assets/img/portfolio/mobile/4.png";
import Partner5M from "../../assets/img/portfolio/mobile/5.png";
import Partner6M from "../../assets/img/portfolio/mobile/6.png";
import Partner7M from "../../assets/img/portfolio/mobile/7.png";
import Partner8M from "../../assets/img/portfolio/mobile/8.png";
import Partner9M from "../../assets/img/portfolio/mobile/9.png";
import Partner10M from "../../assets/img/portfolio/mobile/10.png";
import Partner11M from "../../assets/img/portfolio/mobile/11.png";
import Partner12M from "../../assets/img/portfolio/mobile/12.png";
import Partner13M from "../../assets/img/portfolio/mobile/13.png";
import Partner14M from "../../assets/img/portfolio/mobile/14.png";
import Partner15M from "../../assets/img/portfolio/mobile/15.png";
import Partner16M from "../../assets/img/portfolio/mobile/16.png";
import Partner17M from "../../assets/img/portfolio/mobile/17.png";
import Partner18M from "../../assets/img/portfolio/mobile/18.png";
import Partner19M from "../../assets/img/portfolio/mobile/19.png";
import Partner20M from "../../assets/img/portfolio/mobile/20.png";

import PartnerWhite1 from "../../assets/img/portfolio/desktop/1-w.png";
import PartnerWhite2 from "../../assets/img/portfolio/desktop/2-w.png";
import PartnerWhite3 from "../../assets/img/portfolio/desktop/3-w.png";
import PartnerWhite4 from "../../assets/img/portfolio/desktop/4-w.png";
import PartnerWhite5 from "../../assets/img/portfolio/desktop/5-w.png";
import PartnerWhite6 from "../../assets/img/portfolio/desktop/6-w.png";
import PartnerWhite7 from "../../assets/img/portfolio/desktop/7-w.png";
import PartnerWhite8 from "../../assets/img/portfolio/desktop/8-w.png";
import PartnerWhite9 from "../../assets/img/portfolio/desktop/9-w.png";
import PartnerWhite10 from "../../assets/img/portfolio/desktop/10-w.png";
import PartnerWhite11 from "../../assets/img/portfolio/desktop/11-w.png";
import PartnerWhite12 from "../../assets/img/portfolio/desktop/12-w.png";
import PartnerWhite13 from "../../assets/img/portfolio/desktop/13-w.png";
import PartnerWhite14 from "../../assets/img/portfolio/desktop/14-w.png";
import PartnerWhite15 from "../../assets/img/portfolio/desktop/15-w.png";
import PartnerWhite16 from "../../assets/img/portfolio/desktop/16-w.png";
import PartnerWhite17 from "../../assets/img/portfolio/desktop/17-w.png";
import PartnerWhite18 from "../../assets/img/portfolio/desktop/18-w.png";
import PartnerWhite19 from "../../assets/img/portfolio/desktop/19-w.png";
import PartnerWhite20 from "../../assets/img/portfolio/desktop/20-w.png";
import PartnerWhite21 from "../../assets/img/portfolio/desktop/21-w.png";
import PartnerWhite22 from "../../assets/img/portfolio/desktop/22-w.png";
import PartnerWhite23 from "../../assets/img/portfolio/desktop/23-w.png";
import PartnerWhite24 from "../../assets/img/portfolio/desktop/24-w.png";
import PartnerWhite25 from "../../assets/img/portfolio/desktop/25-w.png";
import PartnerWhite26 from "../../assets/img/portfolio/desktop/26-w.png";
import PartnerWhite27 from "../../assets/img/portfolio/desktop/27-w.png";
import PartnerWhite28 from "../../assets/img/portfolio/desktop/28-w.png";
import PartnerWhite29 from "../../assets/img/portfolio/desktop/29-w.png";
import PartnerWhite30 from "../../assets/img/portfolio/desktop/30-w.png";

import PartnerBlack1 from "../../assets/img/portfolio/desktop/1-b.png";
import PartnerBlack2 from "../../assets/img/portfolio/desktop/2-b.png";
import PartnerBlack3 from "../../assets/img/portfolio/desktop/3-b.png";
import PartnerBlack4 from "../../assets/img/portfolio/desktop/4-b.png";
import PartnerBlack5 from "../../assets/img/portfolio/desktop/5-b.png";
import PartnerBlack6 from "../../assets/img/portfolio/desktop/6-b.png";
import PartnerBlack7 from "../../assets/img/portfolio/desktop/7-b.png";
import PartnerBlack8 from "../../assets/img/portfolio/desktop/8-b.png";
import PartnerBlack9 from "../../assets/img/portfolio/desktop/9-b.png";
import PartnerBlack10 from "../../assets/img/portfolio/desktop/10-b.png";
import PartnerBlack11 from "../../assets/img/portfolio/desktop/11-b.png";
import PartnerBlack12 from "../../assets/img/portfolio/desktop/12-b.png";
import PartnerBlack13 from "../../assets/img/portfolio/desktop/13-b.png";
import PartnerBlack14 from "../../assets/img/portfolio/desktop/14-b.png";
import PartnerBlack15 from "../../assets/img/portfolio/desktop/15-b.png";
import PartnerBlack16 from "../../assets/img/portfolio/desktop/16-b.png";
import PartnerBlack17 from "../../assets/img/portfolio/desktop/17-b.png";
import PartnerBlack18 from "../../assets/img/portfolio/desktop/18-b.png";
import PartnerBlack19 from "../../assets/img/portfolio/desktop/19-b.png";
import PartnerBlack20 from "../../assets/img/portfolio/desktop/20-b.png";
import PartnerBlack21 from "../../assets/img/portfolio/desktop/21-b.png";
import PartnerBlack22 from "../../assets/img/portfolio/desktop/22-b.png";
import PartnerBlack23 from "../../assets/img/portfolio/desktop/23-b.png";
import PartnerBlack24 from "../../assets/img/portfolio/desktop/24-b.png";
import PartnerBlack25 from "../../assets/img/portfolio/desktop/25-b.png";
import PartnerBlack26 from "../../assets/img/portfolio/desktop/26-b.png";
import PartnerBlack27 from "../../assets/img/portfolio/desktop/27-b.png";
import PartnerBlack28 from "../../assets/img/portfolio/desktop/28-b.png";
import PartnerBlack29 from "../../assets/img/portfolio/desktop/29-b.png";
import PartnerBlack30 from "../../assets/img/portfolio/desktop/30-b.png";

import "./portfolio.scss";

import { useTranslation } from "react-i18next";

const Portfolio = () => {
  const { t } = useTranslation();

  return (
    <>
      <Element
        name="portfolio"
        className="portfolio"
        style={{ overflow: "hidden" }}
      >
        <Container>
          <div className="parent-box">
            <Row>
              <Col md={12}>
                <Fade top delay={500} duration={1000}>
                  <div className="top">
                    <div className="title">{t("Портфолио")}</div>
                  </div>
                </Fade>
              </Col>
            </Row>
          </div>
        </Container>

        <div className="partners-desktop">
          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack1} alt="" />
              <img className="white" src={PartnerWhite1} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack2} alt="" />
              <img className="white" src={PartnerWhite2} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack3} alt="" />
              <img className="white" src={PartnerWhite3} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack4} alt="" />
              <img className="white" src={PartnerWhite4} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack5} alt="" />
              <img className="white" src={PartnerWhite5} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack6} alt="" />
              <img className="white" src={PartnerWhite6} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box" style={{ width: "65%" }}>
              <img className="black" src={PartnerBlack7} alt="" />
              <img className="white" src={PartnerWhite7} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack8} alt="" />
              <img className="white" src={PartnerWhite8} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack9} alt="" />
              <img className="white" src={PartnerWhite9} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack10} alt="" />
              <img className="white" src={PartnerWhite10} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack11} alt="" />
              <img className="white" src={PartnerWhite11} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack12} alt="" />
              <img className="white" src={PartnerWhite12} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack13} alt="" />
              <img className="white" src={PartnerWhite13} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack14} alt="" />
              <img className="white" src={PartnerWhite14} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack15} alt="" />
              <img className="white" src={PartnerWhite15} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box" style={{ width: "65%" }}>
              <img className="black" src={PartnerBlack16} alt="" />
              <img className="white" src={PartnerWhite16} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box" style={{ width: "65%" }}>
              <img className="black" src={PartnerBlack17} alt="" />
              <img className="white" src={PartnerWhite17} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box" style={{ width: "65%" }}>
              <img className="black" src={PartnerBlack18} alt="" />
              <img className="white" src={PartnerWhite18} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack19} alt="" />
              <img className="white" src={PartnerWhite19} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack20} alt="" />
              <img className="white" src={PartnerWhite20} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack21} alt="" />
              <img className="white" src={PartnerWhite21} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack22} alt="" />
              <img className="white" src={PartnerWhite22} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack23} alt="" />
              <img className="white" src={PartnerWhite23} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack24} alt="" />
              <img className="white" src={PartnerWhite24} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack25} alt="" />
              <img className="white" src={PartnerWhite25} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack26} alt="" />
              <img className="white" src={PartnerWhite26} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box" style={{ width: "65%" }}>
              <img className="black" src={PartnerBlack27} alt="" />
              <img className="white" src={PartnerWhite27} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box" style={{ width: "65%" }}>
              <img className="black" src={PartnerBlack28} alt="" />
              <img className="white" src={PartnerWhite28} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img className="black" src={PartnerBlack29} alt="" />
              <img className="white" src={PartnerWhite29} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box" style={{ width: "65%" }}>
              <img className="black" src={PartnerBlack30} alt="" />
              <img className="white" src={PartnerWhite30} alt="" />
            </div>
          </div>
        </div>

        <div className="partners-mobile">
          <div className="item">
            <div className="image-box">
              <img src={Partner1M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner2M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner3M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner4M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner5M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner6M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner7M} style={{width: '65%'}} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner8M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner9M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner10M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner11M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner12M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner13M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner14M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner15M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner16M} style={{width: '65%'}} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner17M} style={{width: '65%'}} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner18M} style={{width: '65%'}} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner19M} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="image-box">
              <img src={Partner20M} alt="" />
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};

export default Portfolio;
