import React from "react";
import "./footer.scss";
import { Container } from "react-bootstrap";
import { Element } from "react-scroll/modules";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Element name="footer" className="footer" style={{ overflow: "hidden" }}>
      <Container>
        <div className="row">
          <div className="col-xl-3 col-sm-12 col-md-12 col-12">
            <a href="#" className="brand">
              <img src="/assets/images/logo.png" alt="" />
            </a>
            <div className="brand-text">
              {t("Надежные решения для вашего успеха.")}
            </div>
            <div className="social-media">
              <a
                target="_blank"
                href="https://www.instagram.com/digitalmarketing.uz?igsh=MWJpeDA1ZmhxZjdrYQ%3D%3D&utm_source=qr"
              >
                <img src="/assets/images/instagram-line.svg" alt="" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/digitalmarketinguz/"
              >
                <img src="/assets/images/linkedin-fill.svg" alt="" />
              </a>
              <a
                target="_blank"
                href="https://wa.me/message/QY35AP3Z7LNWH1"
              >
                <img src="/assets/images/whatsapp.svg" alt="" />
              </a>
              <a target="_blank" href="https://t.me/Abdulazizbiz">
                <img src="/assets/images/telegram.svg" alt="" />
              </a>
            </div>
          </div>
          <div className="col-xl-2 col-sm-6 col-md-6 col-6">
            <div className="text">{t("Страницы")}</div>
            <ul>
              <li>
                <a href="#portfolio">{t("Портфолио")}</a>
              </li>
              <li>
                <a href="#services">{t("Услуги")}</a>
              </li>
              <li>
                <a href="#advantages">{t("Преимущества")}</a>
              </li>
              <li>
                <a href="#reviews">{t("Отзывы")}</a>
              </li>
              <li>
                <a href="#faq">{t("Вопросы")}</a>
              </li>
            </ul>
          </div>
          <div className="col-xl-3 col-sm-6 col-md-6 col-6">
            <div className="text">{t("Телефон")}</div>
            <a href="tel:+998333303304" className="social-link">
              +998 (33)-330-33-04
            </a>

            <div className="text mt-4 mb-0">{t("Email")}</div>
            <a href="mailto:info@digitalmarket.uz" className="social-link" style={{marginTop:"12px"}}>
              info@digitalmarket.uz
            </a>

            <div className="office">{t("Офис в Ташкенте")}</div>
          </div>

          <div className="col-xl-4 col-sm-12 col-md-12 col-12">
            <div className="text">{t("Следите за нами в Instagram")}</div>

            <div className="instagram-video">
              <a
                target="_blank"
                href="https://www.instagram.com/p/C0eiFD8KJPm/"
              >
                <img src="/assets/instagram/img1.png" alt="" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/p/C0UNCcxKt9F/"
              >
                <img src="/assets/instagram/img2.png" alt="" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/p/C0TMaMJqN1Y/?igsh=MWJpeDA1ZmhxZjdrYQ%3D%3D&utm_source=qr&img_index=1"
              >
                <img src="/assets/instagram/img3.png" alt="" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/p/C0b6a7QKvpc/"
              >
                <img src="/assets/instagram/img4.png" alt="" />
              </a>
            </div>

            <div className="instagram-video">
              <a
                target="_blank"
                href="https://www.instagram.com/p/C0OxMQfKX9i/"
              >
                <img src="/assets/instagram/img5.png" alt="" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/p/CxMzBnsIHmQ/"
              >
                <img src="/assets/instagram/img6.png" alt="" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/p/C0WtpcVKuVG/?igsh=MWJpeDA1ZmhxZjdrYQ%3D%3D&utm_source=qr&img_index=1"
              >
                <img src="/assets/instagram/img7.png" alt="" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/p/CxHtoZboNBg/"
              >
                <img src="/assets/instagram/img8.png" alt="" />
              </a>
            </div>
          </div>
        </div>

        <hr />

        <div className="digital-text">
          <span className="me-2">
            {t("2021 Цифровой маркетинг. Все права защищены")}
          </span>
          <img
            style={{ transform: "translateY(-1px)" }}
            src="/assets/images/copyright.svg"
            alt=""
          />
        </div>
      </Container>
    </Element>
  );
};

export default Footer;
